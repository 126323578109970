// import hu from "../images/marion-michele-1481926-unsplash.jpg";

export const homePageData = {
    eyeCatcher: 'Hi! I am Sanket',
    introText: `I am a fullstack developer from India. I love building fast, responsive and complex stuff. 
    I look forward to having a great life and making people around me happy. I am a huge fan of having new experiences and 
    I think that we learn the most when thrown into unexpected situations. I love sunsets, beaches and chinese food. I truly beleive in having fun in life 
    and being empathetic to others.`
}

export const projectData = [
{
    imageUrl: 'https://source.unsplash.com/random',
    title: `New Card`,
    description: `I am a fullstack developer from India. I love building fast, responsive and complex stuff.
    I look forward to havin `,
    tags: [
        `React`,
        `Javascript`,
        `Redux`,
        `Gatsby`,
        `Java`,
        `Angular`
    ],
    siteLink: `#`
},
{
    imageUrl: 'https://source.unsplash.com/random',
    title: `New Card`,
    description: `I am a fullstack developer from India. I love building fast, responsive and complex stuff.
    I look forward to havin `,
    tags: [
        `React`,
        `Javascript`,
        `Redux`,
        `Gatsby`,
        `Java`,
        `Angular`
    ],
    siteLink: `#`
},
{
    imageUrl: 'https://source.unsplash.com/random',
    title: `New Card`,
    description: `I am a fullstack developer from India. I love building fast, responsive and complex stuff.
    I look forward to havin `,
    tags: [
        `React`,
        `Javascript`,
        `Redux`,
        `Gatsby`,
        `Java`,
        `Angular`
    ],
    siteLink: `#`
},
{
    imageUrl: 'https://source.unsplash.com/random',
    title: `New Card`,
    description: `I am a fullstack developer from India. I love building fast, responsive and complex stuff.
    I look forward to havin `,
    tags: [
        `React`,
        `Javascript`,
        `Redux`,
        `Gatsby`,
        `Java`,
        `Angular`
    ],
    siteLink: `#`
},
]

// export default [];
